import React from 'react';
import styled from 'styled-components';
import { format } from 'date-fns';
import { Page, Section, Container, SectionTitle } from 'src/components';
import { GatsbyImage } from 'gatsby-plugin-image';

const SPost = styled.div`
  flex: 1;
  width: 720px;
  max-width: 100%;
  margin-top: calc(var(--height-nav) / 2);
  margin-bottom: var(--height-nav);
  max-width: 1024px;
  margin-left: auto;
  margin-right: auto;
  .article {
    max-width: var(--width-read);
  }
  article {
    max-width: 720px;
    margin: auto;
  }
`;

const SImgOuter = styled.div`
  width: 100%;
  height: 500px;
  max-width: var(--width-nav);
  margin: auto;
  overflow: hidden;
  border-radius: 24px;
  > div {
    max-height: 100%;
  }
`;

/**
 * @todo change posts to markdown
 * @todo related posts
 */
const BlogPost = ({ pageContext }) => {
  const { post, personas = [], verticals = [] } = pageContext;

  const dt = new Date(Date.parse(post?.postDate));
  const dtDateOnly = new Date(dt.valueOf() + dt.getTimezoneOffset() * 60 * 1000);

  return (
    <Page
      type="article"
      metadata={{
        pageType: 'BlogPost',
        title: post?.title,
        description: post?.description,
        datePublished: post?.postDate,
        dateModified: post?.updatedAt,
        image: post?.image?.url,
        canonical: `https://catch.co/post/${post?.slug}`,
        keywords: [
          ...personas, // @deprecated
          ...verticals, // @deprecated
          ...(post?.tagset?.verticals || []),
          ...(post?.tagset?.personas || []),
          ...(post?.tagset?.topics || []),
          'post',
          'blog',
          'guide',
          'resource',
        ],
      }}
    >
      <Section>
        <div className="row" style={{ alignItems: 'flex-start' }}>
          <SPost>
            <Container narrow>
              <SectionTitle title={post?.title} subtitle={post?.subtitle || post?.teaserTldr} />
              {post?.postDate && (
                <b2 style={{ marginTop: -24, marginBottom: 24, color: 'var(--c-fg-1)' }}>
                  {!!post?.postDate ? format(dtDateOnly, 'MMMM d, Y', {}) : ''}
                </b2>
              )}

              {/* {post?.cta && (
                <div style={{ margin: '24px 0' }}>
                  <Callout
                    title={post?.cta?.title}
                    text={post?.cta?.text}
                    buttonText={post?.cta?.button}
                    buttonUrl={post?.cta?.url}
                    bg={colors[post?.vertical] || 'var(--g-cerise-light)'}
                  />
                </div>
              )} */}
            </Container>
            <br />
            {!!post?.image ? (
              !!post?.hasSmallIcon ? (
                <SImgOuter>
                  <GatsbyImage image={post?.image?.gatsbyImageData} aspectRatio={16 / 9} />
                </SImgOuter>
              ) : (
                <SImgOuter>
                  <GatsbyImage image={post?.image?.gatsbyImageData} aspectRatio={16 / 9} />
                </SImgOuter>
              )
            ) : (
              <></>
            )}
            <br /> <br />
            <article className="article article-narrow">
              <div dangerouslySetInnerHTML={{ __html: post?.body?.html }} />
            </article>
            {/* {post?.cta && (
              <Container narrow>
                <div style={{ margin: '72px 0 0' }}>
                  <Callout
                    title={post?.cta?.title}
                    text={post?.cta?.text}
                    buttonText={post?.cta?.button}
                    buttonUrl={post?.cta?.url}
                    bg="var(--g-cerise-light)"
                  />
                </div>{' '}
              </Container>
            )} */}
          </SPost>
        </div>
      </Section>
    </Page>
  );
};

// export async function config() {
//   return ({ params }) => {
//     return {
//       defer: true,
//     };
//   };
// }

export default BlogPost;
